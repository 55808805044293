import { Component, Injector, OnInit, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { TranslateService } from '@ngx-translate/core';
import { BotService } from '../../services/botService';
import { Router } from '@angular/router';
import { UserConfig } from 'src/app/entities/userConfig';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    choosenlang : string;
    isNavSearchVisible: boolean;
    router: Router;
     // the fullscreen button
    userConfig: UserConfig;
    logoPath: string = 'assets/img/pae-logo.jpg';
    logoSmallPath: string = 'assets/img/pae-logo-chico.png';
    @ViewChild('fsbutton', {static: true}) fsbutton;
    constructor(public injector: Injector,public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, 
        public translate: TranslateService, private conversationService: BotService, /*private appconfig: AppConfigService*/) {
        
        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.conversationService.getAllConfiguration().subscribe( res => {
            this.logoPath = res.find(x => x.configurationId == 'logoBig').configurationValue;
            this.logoSmallPath = res.find(x => x.configurationId == 'logoSmall').configurationValue;
            if(this.logoPath == null)
                this.logoPath = 'assets/img/pae-logo.jpg';
            if(this.logoSmallPath == null)
                this.logoSmallPath = 'assets/img/pae-logo-chico.png';
        }, error => {
            this.logoPath = 'assets/img/pae-logo.jpg';
            this.logoSmallPath = 'assets/img/pae-logo-chico.png';
        });
    }

    ngOnInit() {
        this.isNavSearchVisible = false;
        
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }
}
