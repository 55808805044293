import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserConfig } from 'src/app/entities/userConfig';
import { BotService } from 'src/app/services/botService';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';

@Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {

    currentTheme: any;
    choosenlang : string;
    userConfig: UserConfig;

    constructor(private toast: ToastrService, public settings: SettingsService, public themes: ThemesService, public translator: TranslatorService,
        public translate: TranslateService, private conversationService: BotService, public elem: ElementRef) {
        translate.addLangs(['English', 'Español']);
        translate.setDefaultLang('English');
        
        this.conversationService.GetUserConfigByUser(localStorage.getItem('userId')).subscribe( res => {
            this.userConfig = res.value;
            if(this.userConfig == null){
                this.choosenlang = 'English';
                this.currentTheme = 'S';
            } else {
                this.choosenlang = this.userConfig.userLanguage;
                this.currentTheme = this.userConfig.userColor;
            }
            this.switchLang(this.choosenlang);
            this.themes.setTheme(this.currentTheme);
        }, error => {
            translate.setDefaultLang('English');
        });
    }

    ngOnInit() {
        this.anyClickClose();
    }

    setTheme() {
        this.themes.setTheme(this.currentTheme);
        this.setUserConfig(null);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    anyClickClose() {
        document.addEventListener('click', this.checkCloseOffsidebar, false);
    }

    checkCloseOffsidebar = e => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        if (!contains) {
            this.settings.setLayoutSetting('offsidebarOpen', false);
        }
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.checkCloseOffsidebar);
    }
    switchLang(lang: string) {
        this.translate.use(lang);
        this.setUserConfig(lang);
        this.toast.clear();
    }

    setUserConfig(lang){
        if(this.userConfig != null){
            if(lang != null)
                this.userConfig.userLanguage = lang;
            if(this.choosenlang != this.userConfig.userLanguage || this.currentTheme != this.userConfig.userColor){
                this.userConfig.userColor = this.currentTheme;
                this.conversationService.SetUserConfigByUser(this.userConfig).subscribe( res => {
                    this.userConfig = res.value;
                    this.choosenlang = this.userConfig.userLanguage;
                    this.currentTheme = this.userConfig.userColor;
                }, error => {
                });
            }
        } else {
            this.userConfig = new UserConfig();
            this.userConfig.userId = localStorage.getItem('userId');
            this.userConfig.userLanguage = lang == null? 'English' : lang;
            this.choosenlang = lang;
            this.userConfig.userColor = this.currentTheme;
            this.conversationService.SetUserConfigByUser(this.userConfig).subscribe( res => {
                this.userConfig = res.value;
                this.choosenlang = this.userConfig.userLanguage;
                this.currentTheme = this.userConfig.userColor;
            }, error => {
            });
        }
    }
}
