import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdentityUser } from '../entities/identityUser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../services/AppConfigService';
import { IdentityRole } from '../entities/identityRole';
import { UserAndRoles } from '../entities/userAndRoles';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AdministrationService {
  


constructor(private http: HttpClient, private environment: AppConfigService, private OAuth: OAuthService) { }

  apiServiceBot = this.environment.config.bot.urlServiceBot;
  pathControlador = '/api/v1/Administration/';
  pathControlador2 = '/api/v2/Administration/';
  pathEntities= '/api/v2/Entities/';

  getAllUsers(): Observable<IdentityUser[]> {
    let pathMetodo = 'GetAllUsers';
    return this.http.get<IdentityUser[]>(this.apiServiceBot + this.pathControlador + pathMetodo,  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  createUsers(usuario: IdentityUser): Observable<string> {
    let pathMetodo = this.apiServiceBot + this.pathControlador + 'CreateUsers';
    return this.http.post<string>(pathMetodo,
      usuario, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  assignRolesToUser(usuarioYRoles: UserAndRoles): Observable<boolean> {
    let pathMetodo = this.apiServiceBot + this.pathControlador + 'AssignRolesToUser';
    return this.http.post<boolean>(pathMetodo,
      usuarioYRoles, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }
  getAllRoles(): Observable<IdentityRole[]> {
    let pathMetodo = 'GetAllRoles';
    return this.http.get<IdentityRole[]>(this.apiServiceBot + this.pathControlador + pathMetodo, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteUser(id: string): Observable<boolean> {
    let pathMetodo = 'DeleteUser/';
    return this.http.delete<boolean>(this.apiServiceBot + this.pathControlador + pathMetodo + id, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  };

  getRolesByUserId(id: string): Observable<IdentityRole[]> {
    let pathMetodo = 'GetRolesByUserId/';
    return this.http.get<IdentityRole[]>(this.apiServiceBot + this.pathControlador + pathMetodo + id, {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Accept": "application/json",
      'Authorization': 'Bearer ' + this.OAuth.getAccessToken()
    })
  })
      .pipe(
        catchError(this.handleError)
      );
  };

  getRolesByUser(userName: string): Observable<IdentityRole[]> {
    let pathMetodo = 'GetRolesByUser/';
    return this.http.get<IdentityRole[]>(this.apiServiceBot + this.pathControlador + pathMetodo + userName)
      .pipe(
        catchError(this.handleError)
      );
  };
  validateUser(userName: string, email:string): Observable<boolean> {
    let pathMetodo = 'ValidateUser/';
    return this.http.get<boolean>(this.apiServiceBot + this.pathControlador + pathMetodo + userName+"/"+email)
      .pipe(
        catchError(this.handleError)
      );
  };

  //Métodos de Errores
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
     // console.error('Ocurrió un error:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Código ${error.status}, ` +
      //   `Mensaje: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened, please try again.');
  };
}